import React from 'react';

import { isProduction } from './stages';

export const counterID = isProduction ? 4173733 : 87713619;

export const YandexMetric = () => {
    return (
        <>
            <script
                dangerouslySetInnerHTML={{
                    __html: `
          (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
          m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
          (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

          ym(${counterID}, "init", {
            accurateTrackBounce: true,
            clickmap: true,
            ecommerce: 'dataLayer',
            trackLinks: true,
            triggerEvent: true,
          });
      `,
                }}
                id="yandex-metric"
                async
            />
        </>
    );
};

export const YandexMetricNoScript = () => {
    return (
        <noscript>
            <div>
                <img
                    alt=""
                    src={`https://mc.yandex.ru/watch/${counterID}`}
                    style={{ left: -9999, position: 'absolute' }}
                />
            </div>
        </noscript>
    );
};
