export default func =>
    (...data) => {
        const { event, ...analytic } = func(...data);

        if (window && window.dataLayer) {
            window.dataLayer.push({
                event: 'ga4',
                event_name: event,
                ...analytic,
            });
        }
    };
