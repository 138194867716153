import { COOKIES } from 'consts';
import { pathOr } from 'ramda';
import { cookieService, SessionService } from 'services';
import { getSessionValue } from 'utils/storage';

import { actions } from './actions';

const getStringFromParams = params =>
    Object.entries(params)
        .reduce((curr, [label, value]) => {
            return [...curr, `${label}:${value}`];
        }, [])
        .join(';');

export const sendMyProfileEvent = ({
    eventActionPath,
    eventLabel = '',
    eventValue = 0,
    eventInteraction = 0,
    flightDetail,
    eventUX,
    ...other
}) => {
    const eventCategory = 'MyProfile';

    return {
        event: 'GAevent',
        eventAction: pathOr('', [eventCategory, ...eventActionPath], actions),
        eventCategory,
        eventInteraction,
        eventLabel,
        eventUX,
        eventValue,
        flightDetail,
        ...other,
    };
};

export const sendMyProfileRegEvent = ({
    eventActionPath,
    eventLabel = '',
    eventValue = 0,
    eventInteraction = 0,
    flightDetail,
    eventUX,
    ...other
}) => {
    const eventCategory = 'MyProfile REG_FLOW';

    return {
        event: 'GAevent',
        eventAction: pathOr('', [eventCategory, ...eventActionPath], actions),
        eventCategory,
        eventInteraction,
        eventLabel: eventLabel ? getStringFromParams(eventLabel) : eventLabel,
        eventUX,
        eventValue,
        flightDetail,
        ...other,
    };
};

export const sendMyProfileMilesEvent = ({
    eventActionPath,
    eventLabel = '',
    eventValue = '0',
    eventInteraction = '0',
    flightDetail,
    eventUX,
}) => {
    const eventCategory = 'MyProfile Miles';

    return {
        event: 'GAevent',
        eventAction: pathOr('', [eventCategory, ...eventActionPath], actions),
        eventCategory,
        eventInteraction,
        eventLabel,
        eventUX,
        eventValue,
        flightDetail,
    };
};

export const sendMyProfileMilesErrorEvent = ({
    eventActionPath,
    eventLabel = '',
    eventValue = '0',
    eventInteraction = '0',
    flightDetail,
    eventUX,
}) => {
    const eventCategory = 'MyProfile Miles Errors';

    return {
        event: 'GAevent',
        eventAction: pathOr('', [eventCategory, ...eventActionPath], actions),
        eventCategory,
        eventInteraction,
        eventLabel,
        eventUX,
        eventValue,
        flightDetail,
    };
};

export const sendUserInfoEvent = (...args) => {
    const ffpMember = getSessionValue('ffpMember');
    const b2bCompanyId = getSessionValue('b2bCompanyId');
    const auth = cookieService.get(COOKIES.AUTH);

    const userId = SessionService.getResourceId();

    return [
        {
            auth: auth || '0',
            b2bCompanyId:
                b2bCompanyId === 'undefined' ? undefined : b2bCompanyId,
            ffpMember: ffpMember === 'undefined' ? undefined : ffpMember,
            userId: userId === 'undefined' ? undefined : userId,
            ...args,
        },
    ];
};

export const sendMyProfileGroupEvent = ({
    eventAction,
    eventLabel = '',
    eventValue = '0',
    eventInteraction = '0',
    flightDetail,
    eventUX,
}) => {
    const eventCategory = 'MyProfile_Group';

    return {
        event: 'GAevent',
        eventAction,
        eventCategory,
        eventInteraction,
        eventLabel,
        eventUX,
        eventValue,
        flightDetail,
    };
};

export const sendMyProfileTravelsEvent = ({
    eventAction,
    eventLabel = {},
    eventValue = 0,
    flightDetail,
    eventInteraction = 0,
    eventUX,
}) => {
    const eventCategory = 'MyProfile Travels';

    return {
        event: 'GAevent',
        eventAction,
        eventCategory,
        eventInteraction,
        eventLabel: getStringFromParams(eventLabel),
        eventUX: eventUX ? getStringFromParams(eventUX) : undefined,
        eventValue,
        flightDetail: flightDetail
            ? getStringFromParams(flightDetail)
            : undefined,
    };
};

export const sendMyProfilePhysicalCard = ({
    eventInteraction = 0,
    eventAction,
    eventCategory,
    eventLabel,
    flightDetail,
}) => {
    return {
        event: 'GAevent',
        eventAction,
        eventCategory,
        eventInteraction,
        eventLabel: getStringFromParams(eventLabel),
        eventUX: undefined,
        eventValue: 0,
        flightDetail: flightDetail
            ? getStringFromParams(flightDetail)
            : 'undefined',
    };
};

export const sendMyProfileSubscription = ({
    eventAction,
    eventCategory = 'myprofile_boost',
    eventLabel = {},
    eventUX = {},
    eventInteraction = 0,
    eventValue = 0,
    flightDetail = {},
}) => {
    return {
        event: 'GAevent',
        eventAction,
        eventCategory,
        eventInteraction,
        eventLabel: getStringFromParams(eventLabel) || undefined,
        eventUX: getStringFromParams(eventUX) || undefined,
        eventValue,
        flightDetail: getStringFromParams(flightDetail) || undefined,
    };
};
