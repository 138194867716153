import React, { createContext, useContext } from 'react';
import { node } from 'prop-types';

import { sendEvent } from './sender';
import GA from './services/googleAnalytics';
import YM from './services/yandexMetric';

const combineFuncs =
    (...funcs) =>
    (...data) =>
        funcs.forEach(func => func(...data));

export const value = {
    sendEvent: combineFuncs(YM(sendEvent), GA(sendEvent)),
};

export const Context = createContext(value);

export const AnalyticsContextProvider = ({ children }) => {
    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useAnalyticsContext = () => {
    const context = useContext(Context);

    if (context === undefined) {
        throw Error(
            'useAnalyticsContextProvider must be used within a AnalyticsContextProvider',
        );
    }

    return context;
};

AnalyticsContextProvider.propTypes = {
    children: node,
};
