import React, { createContext, useContext } from 'react';
import { node, object } from 'prop-types';

export const AppContext = createContext();

export const AppContextProvider = ({ children, value }) => {
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
    const context = useContext(AppContext);

    if (context === undefined) {
        throw Error('useAppContext must be used within a AppContextProvider');
    }

    return context;
};

AppContextProvider.propTypes = {
    children: node,
    value: object,
};
